<template>
	<div class="container-fluid">
		
		<DataGrid :config="dataGridConfig" :data="avaluos" @actions="dataGridActions" @ready="buscar_botones">
			<div class="col-sm-2">
				<button class="btn warning-btn" @click="obtener_avaluos">Recargar</button>
			</div>
		</DataGrid>

		<Modal v-if="solicitud.id" :options="{width: '40vw'}" @close="cancelar_reprocesar_solicitud">
			<div class="title">Reprocesar solicitud</div>
			<div class="body">
				<div class="row">
					<label for="solicitudId" class="col-form-label col-sm-6">¿Confirma reprocesar solicitud?</label>
				</div>
			</div>
			<div class="footer">
				<div class="row">
					<div class="col-sm-3"><button class="btn secondary-btn" @click="reprocesar_solicitud">Confirmar</button></div>
					<div class="col-sm-3"><button class="btn danger-btn" @click="cancelar_reprocesar_solicitud">Cancelar</button></div>
				</div>
			</div>
		</Modal>

	</div>
</template>

<script>
	import DataGrid from '@/components/DataGridV2'
	import Modal from '@/components/Modal'

	import api from '@/apps/avaluodigital/api/avaluo'

	export default {
		components: {
			DataGrid, Modal
		}
		,data: function() {
			return {
				dataGridConfig: {
					search: false
					,name: 'avaluos'
					,select_items: false
					,cols: {
						id: {
							text: '#'
							,ordeable: false
						}
						,external_id: {
							text: 'No. Solicitud'
							,ordeable: false
						}
						,propietario: {
							text: 'Propietario'
							,ordeable: false
						}
						,purpose: {
							text: 'Propósito'
							,ordeable: false
						}
						,status_id: {
							text: 'Estatus'
							,ordeable: false
						}
						,valuador: {
							text: 'Valuador'
							,ordeable: false
						}
						,status_reason: {
							text: ' '
							,ordeable: false
						}
						,semaforo: {
							text: ' '
							,ordeable: false
						}
						,detalle: {
							text: ' '
							,ordeable: false
						}
						,reprocesar: {
							text: ' '
							,ordeable: false
						}
					}
					,filters: {
						cols: {
							id: '#'
							,external_id: 'No. Solicitud'
							,propietario: 'Propietario'
							,purpose: 'Propósito'
							,status_id: {
								text: 'Estatus'
							}
							,valuador: 'Valuador'
							,status_reason: ''
							,semaforo: ''
							,detalle: ''
							,reprocesar: ''
						}
					}
					,paginator: {
						pagina_actual: 1
						,total_registros: 1
						,registros_por_pagina: 30
					}
					,mutators: {
						status_id: function(val) {
							switch(val) {
								case 1:
									return '<span class="alert alert-success">Completado</span>';
									break;
								case 2:
									return '<span class="alert alert-primary">En revisión</span>';
									break;
								case 3:
									return '<span class="alert alert-warning">Asignado</span>';
									break;
								case 4:
									return '<span class="alert alert-info">En proceso</span>';
									break;
								case 5:
									return '<span class="alert alert-danger">Detenido</span>';
									break;
								case 6:
									return '<span class="alert alert-light">En captura</span>';
									break;
								case 7:
									return '<span class="alert alert-danger">Rechazado</span>';
									break;
								case 8:
									return '<span class="alert alert-primary">Aprobado</span>';
									break;

							}
						}
						,status_reason: function(val, row){

							if(row.status_id==5 || row.status_id==7){
								const status_reason = row.status_reason ? `${row.status_reason}.` : ''
								const reject_reason = row.reject_reason ? `${row.reject_reason}.` : ''

								let msg = ''
								switch(row.status_id){
									case 5: 
										msg = status_reason;
									break;
									case 7:
										msg = reject_reason;
									break;
								}
								return `
									<div class="tooltip1">
										<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 5.177l8.631 15.823h-17.262l8.631-15.823zm0-4.177l-12 22h24l-12-22zm-1 9h2v6h-2v-6zm1 9.75c-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25 1.25.56 1.25 1.25-.561 1.25-1.25 1.25z"/></svg>
										<span class="tooltiptext">${msg}</span>
									</div>
									`
							}
						}
						,semaforo: function(val,row) {
							switch(row.semaforo){
								case 'rojo':
									return `<div><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" class="bi bi-circle-fill" viewBox="0 0 16 16">
										<circle cx="8" cy="8" r="8"/>
									</svg></div>`
								case 'amarillo':
									return `<div><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="yellow" class="bi bi-circle-fill" viewBox="0 0 16 16">
										<circle cx="8" cy="8" r="8"/>
									</svg></div>`
								case 'verde':
									return `<div><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-circle-fill" viewBox="0 0 16 16">
										<circle cx="8" cy="8" r="8"/>
									</svg></div>`
								default:
									return `<span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="powderblue" class="bi bi-circle-fill" viewBox="0 0 16 16">
										<circle cx="8" cy="8" r="8"/>
									</svg></span>`
									break;
							}
						}
						,detalle: function(val) {
							return `<a href="${val}">
								<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-pencil-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
									<path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
									<path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
								</svg>
							</a>`;
						}
						,reprocesar: function(val,row) {
							if (row.status_id == 5) {
								return `<div data-btn-type="reprocesar" style="cursor:pointer">
										<svg data-solicitud="${row.id}" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="orange" class="bi bi-gear-fill" viewBox="0 0 16 16">
										<path data-solicitud="${row.id}" fill-rule="evenodd" d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 0 0-5.86 2.929 2.929 0 0 0 0 5.858z"/>
										</svg>
									</div>`
								
							} else {
								return `<div><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-gear-fill" viewBox="0 0 16 16">
										<path fill-rule="evenodd" d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 0 0-5.86 2.929 2.929 0 0 0 0 5.858z"/>
										</svg>
									</div>`
							}
						}
					}
				}
				,options: {
					page: 1
					,order_col: 'id'
					,order_dir: 'desc'
					,limit: 20
					,filters: []
				}
				,seleccionar: false
				,seleccion: []
				,avaluos: []
				,avaluo: {
					id: 0
					,external_id: 0
					,propietario: null
					,porpuse: null
					,status_id: 0
					,valuador: null
					,detalle: null
				}
				,solicitud: {
					id: null
				}
				,modal: 0
			}
		}
		,mounted: function() {
			this.obtener_avaluos();
		}
		,methods: {
			swap_token: async function(){
				try{
					const avaluoRps = await api.obtener_token(this.$auth.getAuth())
					const tkn = avaluoRps.data.payload.token
					this.$store.state.tokenAD = avaluoRps.data.payload.token
					
					await api.setGlobalToken(tkn);
				}catch(err){
					this.$store.state.tokenAD = null;
					throw err;
				}
			},
			obtener_avaluos: async function() {
				try {
					await this.swap_token();
					// Obtiene solicitudes a partir de paginación default
					let response = await obtener_avaluo_data()
					// Configuración datagrid para paginado
					this.avaluos = response.data
					this.dataGridConfig.paginator.pagina_actual = 1;
					this.dataGridConfig.paginator.total_registros = response.total
					
				}catch(err){
                  this.$helper.showAxiosError(err, 'Error');
              	}
			}
			,dataGridActions: async function(tipo, valor) {
				if (tipo == 'options') {
					await this.swap_token();
					// Obtiene solicitudes a partir de paginación
					let response = await obtener_avaluo_data(valor.page,valor.limit)
					this.avaluos = response.data
					// Configuración datagrid para paginado
					this.dataGridConfig.paginator.pagina_actual = valor.page;
					this.dataGridConfig.paginator.total_registros = response.total;
					this.dataGridConfig.paginator.registros_por_pagina = valor.limit;
					this.dataGridConfig.paginator.limit = valor.limit;

					this.options = valor;
				}else {
					this.seleccion = valor;
					this.seleccionar = true;
				}
			}
			,buscar_botones: function() {
				let parent = this;

				document.querySelectorAll('div[data-btn-type=reprocesar]').forEach(btn => {
					btn.removeEventListener('click', parent.pre_reprocesar);
					btn.addEventListener('click', parent.pre_reprocesar);
				});
			}
			,pre_reprocesar: function(e) {
				this.solicitud.id = parseInt(e.target.getAttribute('data-solicitud'));
			}
			,reprocesar_solicitud: async function(){
				try {
					this.$log.info('Solicitud por reprocesar: ', this.solicitud.id)

					// Envia solicitud de creación de solicitud
					let response = (await api.start_solicitud(this.solicitud.id)).data;
					this.$log.info('reprocess response: ', response)

					// Muestra mensaje de finazalización ok
					const reprocessMsg = 'Reprocesamiento ha finalizado con éxito'
					this.$log.info(reprocessMsg)
					this.$helper.showMessage(reprocessMsg);
					this.cancelar_reprocesar_solicitud();

					// Refresca avaluos
					this.obtener_avaluos();
				} catch(err){
					this.$log.error(err);
					this.$helper.showAxiosError(err,'Error');
				}
			}
			,cancelar_reprocesar_solicitud: function() {
				this.solicitud = {
					id: null
				};
			}
		}
	}


	const obtener_avaluo_data = async (page,limit) =>{

		let response = (await api.consulta_solicitudes(page,limit)).data.payload;
		let avaluos_response = JSON.parse(response.data);

		let avaluos = avaluos_response.map(function(avaluo){
			avaluo.propietario = avaluo.applicant ? avaluo.applicant.full_name : '';
			avaluo.detalle = "/avaluo_digital/solicitud/" + avaluo.external_id
			
			// Asignar valuador visitante si hay, si no al valuador normal.
			if (avaluo.appraiser_visit){
				avaluo.valuador = avaluo.appraiser_visit ? avaluo.appraiser_visit.user.name : "No asignado";
			} else {
				avaluo.valuador = avaluo.appraiser ? avaluo.appraiser.user.name : "No asignado";
			}
			return avaluo;
		})
		return {
			total: response.total,
			page: response.page,
			data: avaluos
		}
	};
</script>

<style lang="css">

span {
        display:inline-block;
        width: 10em;
        font-weight: normal;
        text-align: center
     }

.tooltip1 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip1:hover .tooltiptext {
  visibility: visible;
}
	
</style>